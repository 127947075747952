import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import id from "../images/ID.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import rupee from "../images/rupee.svg";
import mail from "../images/mail.svg";
import MemberTable from "../Customer/MemberTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AddLocationModal = forwardRef((props, ref2, ref4) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useImperativeHandle(ref2, () => ({
    log() {
      handleOpen();
    },
  }));

  useImperativeHandle(ref4, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content add-product adm-modal">
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              MERCHANT LOCATION DETAILS
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>

          <Box className="ml-20" mt={2}>
            <Typography component="h4" className="sub_title">
              Manager Details
            </Typography>
          </Box>
          <Grid container spacing={3} mb={2} className="px-15">
            <Grid item xs={2} sm={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Manager Name</Typography>
                <OutlinedInput value="" />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Manager Number</Typography>
                <OutlinedInput value="" />
              </FormControl>
            </Grid>
            {/* <Grid item xs={2} sm={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Address</Typography>
                <OutlinedInput value="" />
              </FormControl>
            </Grid> */}
          </Grid>

          <Divider></Divider>
          <Box className="ml-20" mt={2}>
            <Typography component="h4" className="sub_title">
              Location Address
            </Typography>
          </Box>
          <Grid container spacing={2} className="px-15">
            <Grid item md={12}>
              <FormControl className="w-100">
                <Typography component="label">Address</Typography>
                <Box component="textarea" rows={5} placeholder="" />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="px-15">
            <Grid item xs={2} sm={12} md={4}>
              <Box className="information-div">
                <FormControl className="w-100">
                  <Typography component="label">Location Name</Typography>
                  <OutlinedInput value="" />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={2} sm={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Packing Charges</Typography>
                <OutlinedInput value="" />
              </FormControl>
            </Grid>

            <Grid item xs={2} sm={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">City</Typography>

                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="locations"
                >
                  <MenuItem value="locations">All Cities</MenuItem>
                  <MenuItem value="visakhapatnam">Visakhapatnam</MenuItem>
                  <MenuItem value="vijayawada">Vijayawada</MenuItem>

                  <MenuItem value="kurnool">Kurnool</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Type</Typography>

                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="locations"
                >
                  <MenuItem value="locations">Select</MenuItem>
                  <MenuItem value="visakhapatnam">Restaurant</MenuItem>
                  <MenuItem value="vijayawada">Groceries</MenuItem>

                  <MenuItem value="kurnool">Bar</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Box className="ml-20" mt={2}>
            <Typography component="h4" className="sub_title">
              Location Details
            </Typography>
          </Box>
          <Grid container spacing={3} className="px-15">
            <Grid item xs={2} sm={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Location Latitude</Typography>
                <OutlinedInput value="" />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Location Longitude</Typography>
                <OutlinedInput value="" />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={3} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Locality</Typography>
                <OutlinedInput value="" />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Sub locality</Typography>
                <OutlinedInput value="" />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Route</Typography>
                <OutlinedInput value="" />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={3} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Pin Code</Typography>
                <OutlinedInput value="" />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Opening Time</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="locations"
                >
                  <MenuItem value="locations">Select</MenuItem>
                  <MenuItem value="visakhapatnam">09:00 AM</MenuItem>
                  <MenuItem value="vijayawada">10:00 AM</MenuItem>

                  <MenuItem value="kurnool">11:00 AM</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Closing Time</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="locations"
                >
                  <MenuItem value="locations">Select</MenuItem>
                  <MenuItem value="visakhapatnam">09:00 PM</MenuItem>
                  <MenuItem value="vijayawada">10:00 PM</MenuItem>

                  <MenuItem value="kurnool">11:00 PM</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={3} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Opening Time 2</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="locations"
                >
                  <MenuItem value="locations">Select</MenuItem>
                  <MenuItem value="visakhapatnam">09:00 AM</MenuItem>
                  <MenuItem value="vijayawada">10:00 AM</MenuItem>

                  <MenuItem value="kurnool">11:00 AM</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Closing Time 2</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="locations"
                >
                  <MenuItem value="locations">Select</MenuItem>
                  <MenuItem value="visakhapatnam">09:00 PM</MenuItem>
                  <MenuItem value="vijayawada">10:00 PM</MenuItem>

                  <MenuItem value="kurnool">11:00 PM</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Divider></Divider>
          <Box className="ml-20" mt={2}>
            <Typography component="h4" className="sub_title">
              Closed Days
            </Typography>
          </Box>

          <Grid container spacing={3} className="p-15">
            <FormGroup className="flex flex-row align-center flex-between w-100">
              <FormControlLabel control={<Checkbox />} label="Monday" />
              <FormControlLabel control={<Checkbox />} label="Tuesday" />
              <FormControlLabel control={<Checkbox />} label="Wednesday" />
              <FormControlLabel control={<Checkbox />} label="Thusday" />
              <FormControlLabel control={<Checkbox />} label="Friday" />
              <FormControlLabel control={<Checkbox />} label="Saturaday" />
              <FormControlLabel control={<Checkbox />} label="Sunday" />
            </FormGroup>
          </Grid>

          <Divider></Divider>
          <Box className="p-15">
            <Button
              variant="outlined"
              color="error"
              mt={2}
              mb={3}
              className="retail-btn float-right"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
});

export default AddLocationModal;
