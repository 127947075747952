import React, { useImperativeHandle, forwardRef } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import Calender from "../Calender/Calender";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { useNavigate } from "react-router-dom";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import SearchBar from "material-ui-search-bar";
import ExpandableRowTable from "../Customers/ExpandableRowTable";
import email from "../images/mail.svg";
import MemberTable from "../Customer/MemberTable";
import OrderModal from "./OrderModal";
import Bredcrum from "../Bredcrum";

export default function PurchaseOrders() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const memeberColumns = [
    { name: "Date", options: { filterOptions: { fullWidth: true } } },
    "Order ID",
    "Customer",
    "Total Amount",
    "Order Method",
    "Status",
  ];

  const data = [
    [
      "23-07-2023",
      "#773889",
      "Sreenivas Reddy",
      "₹1030",
      "Phone Order",
      "Inprogress",
    ],
  ];

  const pcData = [["#98759", "Rice", "01", "Rs. 300"]];

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    responsive: "scrollMaxHeight",
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const pcColumns = [
    { name: "ORDER ID", options: { filterOptions: { fullWidth: true } } },
    "ORDER NAME",
    "QTY",
    "PRICE",
  ];

  const options = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
      // return (
      //   <React.Fragment>
      //     <tr className="inner-row">
      //       <td colSpan={6}>
      //         <TableContainer>
      //           <Table aria-label="simple table">
      //             <TableBody>
      //               {/* {rows.map(row => ( */}
      //               {/* <TableRow key={row.id}> */}
      //               <TableRow>
      //                 <TableCell scope="row">
      //                   <Typography component="p">
      //                     <b>Taxes:</b> ₹ 485.00
      //                   </Typography>
      //                 </TableCell>
      //               </TableRow>
      //               <TableRow>
      //                 <TableCell scope="row">
      //                   <Typography component="p">
      //                     <b>Advance:</b> ₹ 321.00
      //                   </Typography>
      //                 </TableCell>
      //               </TableRow>
      //               <TableRow>
      //                 <TableCell scope="row">
      //                   <Typography component="p">
      //                     <b>Due Amount:</b> ₹ 542.00
      //                   </Typography>
      //                 </TableCell>
      //               </TableRow>
      //               <TableRow>
      //                 <TableCell scope="row">
      //                   <Typography component="p">
      //                     <b>State:</b> State
      //                   </Typography>
      //                 </TableCell>
      //               </TableRow>
      //               {/* ))} */}
      //             </TableBody>
      //           </Table>
      //         </TableContainer>
      //       </td>
      //     </tr>
      //   </React.Fragment>
      // );
    },
    page: 1,
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid xs="12" mt={1}>
                    <Box
                      sx={{ display: "flex" }}
                      className="filters sales-filters"
                    >
                      <Box className="flex">
                        <Calender className="calenders" />
                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn active"
                        >
                          Today
                        </Button>

                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          Yesterday
                        </Button>
                      </Box>
                      {/* <Box className="flex">
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Locations</MenuItem>
                          <MenuItem value="visakhapatnam">
                            Visakhapatnam
                          </MenuItem>
                          <MenuItem value="vijayawada">Vijayawada</MenuItem>

                          <MenuItem value="kurnool">Kurnool</MenuItem>
                          <MenuItem value="kurnool">Hyderabad</MenuItem>
                          <MenuItem value="kurnool">Guntur</MenuItem>
                        </Select>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Orders</MenuItem>
                          <MenuItem value="visakhapatnam">PDF</MenuItem>
                          <MenuItem value="vijayawada">Excel</MenuItem>

                          <MenuItem value="kurnool">Image</MenuItem>
                        </Select>
                      </Box> */}
                    </Box>
                  </Grid>
                  <Grid container mt={3}>
                    <Grid
                      xs="12"
                      mt={2}
                      mb={2}
                      className="transport-div salesreport-container flex"
                    >
                      <Box className="filters-group">
                        <Box className="sales-report">
                          <Typography component="h4">6</Typography>
                          <Typography
                            component="p"
                            className="btn-primary-gradient"
                          >
                            All
                          </Typography>
                        </Box>
                        <Box className="sales-report">
                          <Typography component="h4">0</Typography>
                          <Typography
                            component="p"
                            className="btn-secondary-gradient"
                          >
                            New
                          </Typography>
                        </Box>
                        <Box className="sales-report">
                          <Typography component="h4">2</Typography>
                          <Typography
                            component="p"
                            className="btn-purple-gradient"
                          >
                            Assigned
                          </Typography>
                        </Box>
                        <Box className="sales-report big-box">
                          <Typography component="h4">4</Typography>
                          <Typography
                            component="p"
                            className="btn-success-gradient"
                          >
                            Pending Ack
                          </Typography>
                        </Box>
                        <Box className="sales-report big-box">
                          <Typography component="h4">150</Typography>
                          <Typography
                            component="p"
                            className="btn-info-gradient"
                          >
                            Ready for Dispatch
                          </Typography>
                        </Box>
                        <Box className="sales-report">
                          <Typography component="h4">1,907</Typography>
                          <Typography
                            component="p"
                            className="btn-warning-gradient"
                          >
                            In Transit
                          </Typography>
                        </Box>
                        <Box className="sales-report">
                          <Typography component="h4">2,388</Typography>
                          <Typography
                            component="p"
                            className="btn-danger-gradient"
                          >
                            Completed
                          </Typography>
                        </Box>
                        <Box className="sales-report">
                          <Typography component="h4">6</Typography>
                          <Typography
                            component="p"
                            className="btn-primary-gradient"
                          >
                            Cancelled
                          </Typography>
                        </Box>
                        <Box className="sales-report">
                          <Typography component="h4">6</Typography>
                          <Typography
                            component="p"
                            className="btn-primary-gradient"
                          >
                            Abandoned
                          </Typography>
                        </Box>
                        <Box className="sales-report">
                          <Typography component="h4">0</Typography>
                          <Typography
                            component="p"
                            className="btn-secondary-gradient"
                          >
                            Hold
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container mt={3} spacing={2}>
                  <Grid item xs={12} md={7}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box className="member-table">
                          <ExpandableRowTable
                            columns={memeberColumns}
                            options={options}
                            data={data}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Card className="customer_cards">
                      <CardContent className="customer_information">
                        <Box>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            mb={2}
                          >
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Order ID:
                                  </Typography>
                                  <Typography>524261444</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Order Status
                                  </Typography>
                                  <Typography>Progress</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Select
                                  labelId="demo-isolation-label"
                                  id="status-select"
                                  className="select float-right"
                                  // onChange={handleSelectOxygen}
                                  // input={<OutlinedInput />}
                                  defaultValue="locations"
                                >
                                  <MenuItem value="locations">
                                    Update Status
                                  </MenuItem>
                                  <MenuItem value="visakhapatnam">
                                    Accepted
                                  </MenuItem>
                                  <MenuItem value="vijayawada">
                                    Completed
                                  </MenuItem>

                                  <MenuItem value="kurnool">Progress</MenuItem>
                                </Select>
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Date Created
                                  </Typography>
                                  <Typography>02-03-2023</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}></Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Button
                                variant="outlined"
                                color="error"
                                mt={2}
                                className="retail-btn"
                                onClick={() => ref3.current.log()}
                              >
                                View More
                              </Button>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            mt={2}
                            mb={2}
                          >
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Customer
                                  </Typography>
                                  <Typography>
                                    Srinivasa Kirana Store
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Mobile
                                  </Typography>
                                  <Typography>+91 90528 45869</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2} mb={2}>
                              <Box className="information-div">
                                <Box component="img" src={email} />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography>
                                    lokesh.bezavada@gmail.com
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2} mb={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    Location
                                  </Typography>
                                  <Typography>Kakinada</Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Divider></Divider>
                          </Grid>
                          <Grid container spacing={2} mb={1}>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Order Method
                                  </Typography>
                                  <Typography>Phone Order</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Order Type
                                  </Typography>
                                  <Typography>Curb Side</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Status
                                  </Typography>
                                  <Typography>On the way</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Delivery
                                  </Typography>
                                  <Typography>Ravi P</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Mobile
                                  </Typography>
                                  <Typography>+91 90528 45869</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Payment Mode
                                  </Typography>
                                  <Typography>UPI</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Payment Status
                                  </Typography>
                                  <Typography>Pending</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider></Divider>
                        <Grid mt={5} className="view-more-btn">
                          <Box className="w-100 member-table">
                            <MemberTable
                              columns={pcColumns}
                              options={pcOptions}
                              data={pcData}
                            />
                          </Box>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <OrderModal ref={ref3} />
      </Box>
    </>
  );
}
