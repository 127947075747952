import React, { useImperativeHandle, forwardRef } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";

import Calender from "../Calender/Calender";
import { Image, Label, Phone } from "@mui/icons-material";
import AddCustomerModal from "../Customer/AddCustomerModal";
import id from "../images/ID.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import MemberTable from "../Customer/MemberTable";
import EditModal from "../Customer/EditModal";
import ViewMoreModal from "../Customer/ViewMoreModal";
import home from "../images/home.svg";
import Bredcrum from "../Bredcrum";

const drawerWidth = 240;

const year = ["2023", "2022", "2021", "2020"];

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function JobApplicants() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = React.useState(true);

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();

  const jobColumns = [
    { name: "ApplicationId", options: { filterOptions: { fullWidth: true } } },
    "Applicant Name",
    "Phone",
    "Email",
  ];

  const jobOptions = {
    search: searchBtn,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: "dropdown",
    selectableRows: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid xs="12" mt={3}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      className="filters"
                    >
                      <Box className="select_div range-picker">
                        <Calender />

                        <Button variant="outlined" className="today">
                          Today
                        </Button>
                        <Button variant="outlined" className="today">
                          Yesterday
                        </Button>
                      </Box>
                      <Box style={{ display: "flex" }}>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Locations</MenuItem>
                          <MenuItem>Hyderabad</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h6">JOB APPLICANTS</Typography>
                        </Box>
                        <Box mt={2}>
                          <MemberTable
                            columns={jobColumns}
                            options={jobOptions}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent className="customer_information applicant_information">
                        <Box>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            mb={2}
                          >
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={id} />
                                <Box>
                                  <Typography component="label">
                                    Name
                                  </Typography>
                                  <Typography>Srinivas Rao</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Phone No.
                                  </Typography>
                                  <Typography>9063476257</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography>srinivasrao@gmail.com</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                  <Typography component="label">
                                    Position Applied
                                  </Typography>
                                  <Typography>Driver</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    Location
                                  </Typography>
                                  <Typography>Hyderabad</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                  <Typography component="label">
                                    Availability
                                  </Typography>
                                  <Typography>FullTime</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    City
                                  </Typography>
                                  <Typography>Hyderabad</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    State
                                  </Typography>
                                  <Typography>Telangana</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={id} />
                                <Box>
                                  <Typography component="label">Zip</Typography>
                                  <Typography>5300004</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                  <Typography component="label">
                                    Commercial passenger transport experience
                                  </Typography>
                                  <Typography>YES</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={home} />
                                <Box>
                                  <Typography component="label">
                                    Address 1
                                  </Typography>
                                  <Typography>
                                    Road No. 3, Hi-Tech City
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={home} />
                                <Box>
                                  <Typography component="label">
                                    Address 2
                                  </Typography>
                                  <Typography>n/a</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                          <Divider></Divider>
                          <Typography component="h2" ml={2} mt={2}>
                            WORK HISTORY
                          </Typography>
                          <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} md={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Previous Job Title
                                  </Typography>
                                  <Typography component="p">
                                    Senior Pharmacy Technician
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Previous Job Description
                                  </Typography>
                                  <Typography>
                                    Filled prescriptions in a timely manner.
                                    Helped customers purchase their
                                    prescriptions, helping finding cheaper
                                    alternatives if need be or communicating
                                    with their physicians for refills or
                                    changes. Managed inventory, ordering and
                                    returning meds. Managed and trained other
                                    pharmacy technicians
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Previous Job Started Date
                                  </Typography>
                                  <Typography>03/2021</Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Previous Job Ended Date
                                  </Typography>
                                  <Typography>09/2022</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider></Divider>
                        <Typography component="h3">REFERENCES</Typography>
                        <Typography component="h4">REFERENCES - 1</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={customers} />
                              <Box>
                                <Typography component="label">Name</Typography>
                                <Typography component="p">Sai Deva</Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={mobile} />
                              <Box>
                                <Typography component="label">
                                  Phone no.
                                </Typography>
                                <Typography>9030369836</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Typography component="h4">REFERENCES - 2</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={customers} />
                              <Box>
                                <Typography component="label">Name</Typography>
                                <Typography component="p">Kumar</Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={mobile} />
                              <Box>
                                <Typography component="label">
                                  Phone no.
                                </Typography>
                                <Typography>8823177482</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Typography component="h4">REFERENCES - 3</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={customers} />
                              <Box>
                                <Typography component="label">Name</Typography>
                                <Typography component="p">Sree Ramu</Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={mobile} />
                              <Box>
                                <Typography component="label">
                                  Phone no.
                                </Typography>
                                <Typography>8121542167</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Typography component="h4">REFERENCES - 4</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={customers} />
                              <Box>
                                <Typography component="label">Name</Typography>
                                <Typography component="p">Akhil</Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={mobile} />
                              <Box>
                                <Typography component="label">
                                  Phone no.
                                </Typography>
                                <Typography>7788123789</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <AddCustomerModal ref={ref} />
        <EditModal ref={ref1} />
        <ViewMoreModal ref={ref3} />
      </Box>
    </>
  );
}
