import React from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  OutlinedInput,
  FormControl,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SearchBar from "material-ui-search-bar";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import id from "../images/ID.svg";
import logout from "../images/logout.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
// import MemberTable from "../Customer/MemberTable";
import EditModal from "../Customer/EditModal";
import Alert from "@mui/material/Alert";
import Switch, { SwitchProps } from "@mui/material/Switch";
import ExpandableRowTable from "../Customers/ExpandableRowTable";
import MessageModal from "../Customers/MessageModal";
import ProfileModal from "../ProfileModal";
import rupee from "../images/rupee.svg";
import ViewMoreModalRetail from "../Customers/ViewMoreModalRetail";
import RetaileModal from "../RetaileModal";
import DatePicker from "react-datepicker";
import SupplierViewMoreModal from "./SupplierViewMoreModal";
import MemberTable from "../Customer/MemberTable";
const drawerWidth = 240;

const style = {
  position: "absolute",
  top: "0",
  right: "0",
  // transform: 'translate(-50%, -50%)',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function Suppliers() {
  const [open, setOpen] = React.useState(false);
  const [showState, setShowState] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [startDate, setStartDate] = React.useState(new Date());
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();
  const ref4 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  const retailerColumns = [
    { name: "Suppliers ID", options: { filterOptions: { fullWidth: true } } },
    "Suppliers Name",
  ];

  const data = [
    ["#147258", "VENKATA SAI TRADERS"],
    ["#258369", "OM PROVISION"],
    ["#369147", "S BALAJI PROVISION"],
  ];
  const pcColumns = [
    { name: "Supplier ID", options: { filterOptions: { fullWidth: true } } },
    "Supplier Name",
    "Phone Number",
    "Total Orders",
    "City",
    "Supplier Type",
  ];

  const pcData = [
    [
      "#98759",
      "Zeeshan Apna Hyderabadi",
      "8121542167",
      "200",
      "Hyderabad",
      "Restaurant",
    ],
  ];

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    responsive: "scrollMaxHeight",
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const retailerOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container className="retailers">
          <Grid xs="12">
            <Card xs="11" className="custom_card retail_card ">
              <Grid container className="bazaar-breadcrumb">
                <Grid className="inner-bredcum" xs="12">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                      Procurement
                    </Link>

                    <Typography color="text.primary">Supplier</Typography>
                  </Breadcrumbs>
                  <Box className="icons bredcum-icons">
                    <SearchBar className="search-bar" />
                    <PersonIcon onClick={() => ref.current.log()} />

                    <NotificationsIcon />
                    <LogoutIcon onClick={logoutDashboard} ml={2} />
                  </Box>
                </Grid>
              </Grid>
              <CardContent>
                <Grid container className="full_content">
                  <Grid xs="12" mt={1}>
                    <Box className="filters retailer-filters">
                      <Box>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Cities</MenuItem>
                          <MenuItem value="visakhapatnam">
                            Visakhapatnam
                          </MenuItem>
                          <MenuItem value="vijayawada">Vijayawada</MenuItem>

                          <MenuItem value="kurnool">Kurnool</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid container mt={4} className="pickers">
                    <Grid
                      container
                      padding={2}
                      className="pickers member-datepickers"
                    >
                      <Grid item md={3}>
                        <Box className="border-right">
                          <Typography>JOIN DATE</Typography>
                          <Box className="sub_date">
                            <FormControl>
                              <Typography component="label">From</Typography>
                              {/* <OutlinedInput type="date" placeholder="Please enter text" /> */}
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                              />
                            </FormControl>
                            <FormControl>
                              <Typography component="label">To</Typography>
                              <OutlinedInput
                                type="date"
                                placeholder="Please enter text"
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={3}>
                        <Box className="border-right">
                          <Typography>LAST ACTIVE</Typography>
                          <Box className="sub_date">
                            <FormControl>
                              <Typography component="label">From</Typography>
                              <OutlinedInput type="date" />
                            </FormControl>
                            <FormControl>
                              <Typography component="label">To</Typography>
                              <OutlinedInput type="date" />
                            </FormControl>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={3}>
                        <Box className="border-right">
                          <Typography>SPEND</Typography>
                          <Box className="sub_date">
                            <FormControl>
                              <Typography component="label">From</Typography>
                              <OutlinedInput
                                type="number"
                                placeholder="Spend Min"
                              />
                            </FormControl>
                            <FormControl>
                              <Typography component="label">To</Typography>
                              <OutlinedInput
                                type="number"
                                placeholder="Spend Max"
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={3}>
                        <Box>
                          <Typography>NO OF ORDERS</Typography>
                          <Box className="sub_date">
                            <FormControl>
                              <Typography component="label">From</Typography>
                              <OutlinedInput
                                type="number"
                                placeholder="Orders Max"
                              />
                            </FormControl>
                            <FormControl>
                              <Typography component="label">To</Typography>
                              <OutlinedInput
                                type="number"
                                placeholder="Orders Min"
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Grid>
                      {/* <Grid xs="12" mt={2} mb={2}>
                      <Box className="member-datepickers">
                        <Box></Box>
                      </Box>
                    </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box className="box-header" mb={2}>
                          <Typography component="h6">SupplierS</Typography>
                          <Box>
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn"
                              mr={2}
                            >
                              Download
                            </Button>
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn"
                              onClick={() => ref.current.log()}
                            >
                              Send Message
                            </Button>
                          </Box>
                        </Box>
                        <Box className="member-table">
                          {/* <ExpandableRowTable /> */}
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent className="customer_information">
                        <Box>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={id} />
                                <Box>
                                  <Typography component="label">
                                    Supplier ID
                                  </Typography>
                                  <Typography>#258369</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Name
                                  </Typography>
                                  <Typography>VENKATA SAI TRADERS</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Phone no.
                                  </Typography>
                                  <Typography>+91 9948222051</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography>
                                    A2Cclaimsdenver@EMSC.net
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Supplier Type
                                  </Typography>
                                  <Typography>Groceries</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    Address
                                  </Typography>
                                  <Typography>
                                    Stonehouse Pet, Nellore,
                                    <br /> Andhra Pradesh, India
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                  <Typography component="label">
                                    COD Status
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                  <Typography component="label">
                                    Block Status
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Member
                                  </Typography>
                                  {/* <Radio
                                    checked={selectedValue === "a"}
                                    onChange={handleChange}
                                    value="a"
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "A" }}
                                    className="radio-btn"
                                  /> */}
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                  <Typography component="label">
                                    Active Credit Status
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Box className="information-div" mt={2}>
                                <Box component="img" src={rupee} />
                                <Box>
                                  <Typography component="label">
                                    Total Credit Limit
                                  </Typography>
                                  <Typography>Rs. 50,000.00</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div" mt={2} mb={2}>
                                <Box component="img" src={rupee} />
                                <Box>
                                  <Typography component="label">
                                    Available Credit Limit
                                  </Typography>
                                  <Typography>Rs. 20,000.00</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} spacing={2}>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Customer Since
                                  </Typography>
                                  <Typography>02/02/2023</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Last Activity
                                  </Typography>
                                  <Typography>10/03/2023</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={6} mt={2} className="total_orders">
                              <Box className="box-primary">
                                <Typography component="h2">5</Typography>
                                <Typography component="p">
                                  Total Orders
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="customer-report">
                                <Typography component="h2">45000</Typography>
                                <Typography component="p">
                                  Total Spent
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider></Divider>
                        <Grid mt={5} className="view-more-btn">
                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            className="retail-btn"
                            onClick={() => ref3.current.log()}
                          >
                            View More
                          </Button>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="addCustomerModal notification-modal">
            <Box className="modal-header p-10" mb={2}>
              <Typography id="modal-modal-title" variant="h5" component="h5">
                NOTIFICATIONS
              </Typography>
              <CloseIcon onClick={handleClose} />
            </Box>
            <Divider></Divider>
            <Box className="p-10">
              <Alert
                icon={false}
                severity="warning"
                mt={2}
                className="alert-warning"
              >
                <Typography component="h5">Order Received</Typography>
                <Typography component="p" className="small">
                  2min ago
                </Typography>
                <Typography component="p" className="text-p">
                  200 Packets of NILGIRIS Toned Milk Pouch is received from SVN
                  Mart.
                </Typography>
              </Alert>
              <Alert
                icon={false}
                severity="success"
                mt={2}
                className="alert-success"
              >
                <Typography component="h5">Order Requested</Typography>
                <Typography component="p" className="small">
                  12min ago
                </Typography>
                <Typography component="p" className="text-p">
                  200 NUTREEN Nutreen Brown Eggs, 30 Pieces Pack is received
                  from Real Mart.
                </Typography>
              </Alert>
            </Box>
          </Box>
        </Modal>
        {/* <Toolbar /> */}
        <RetaileModal ref={ref4} />
        <MessageModal ref={ref} />
        <EditModal ref={ref1} />
        <SupplierViewMoreModal ref={ref3} />
      </Box>
    </>
  );
}
