import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  Breadcrumbs,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import { FormControlLabel, Radio } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";
import PropTypes from "prop-types";
import SearchBar from "material-ui-search-bar";
import MemberTable from "../Customer/MemberTable";
// import LocationModal from "./LocationModal";
import Bredcrum from "../Bredcrum";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

export default function Publishing() {
  const handleClick = () => {
    setShowState(!showState);
  };
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref4 = React.useRef();
  const ref3 = React.useRef();
  const ref2 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 35,
    height: 17,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 15,
      height: 13,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const pcColumns = [
    { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
    "Retailer Name",
    "Total PO Value",
    "Status",
    "Total PO",
  ];

  const eiColumns = ["ID", "Location", "Name"];
  const eiData = [
    ["#963258", "Srikakulam", "Ramya"],
    ["#963258", "Srikakulam", "Ramya"],
    ["#856214", "Vizianagaram", "Srilekha"],
  ];
  const mlColumns = [
    "DATE",
    "TYPE",
    "TASK",
    "LIFE OF REPAIR",
    "COST",
    "COMMENTS",
  ];

  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,
    pagination: false,
  };
  const ItemColumns = [
    "S.no",
    "Seat No",
    "POS / Price",
    "Phone Order / Price",
    "Waiter Log / Price",
    "App / Price",
    "Website / Price",
  ];
  const itemOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    confirmFilters: false,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const orderItemData1 = [
    [
      "1",
      "Chicken Hakka Noodles",
      "300.00",
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
    ],
    [
      "2",
      "Egg Noodles",
      "300.00",
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
    ],
    [
      "3",
      "Chicken Chilli Garlic Noodles",
      "300.00",
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
    ],
  ];

  const orderItemData = [
    [
      "1",
      "Seat 1",
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
    ],
    [
      "2",
      "Seat 2",
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
    ],
    [
      "3",
      "Seat 3",
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        {/* <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl> */}
      </Box>,
    ],
  ];
  // const orderItemData = [
  //   [
  //     "1",
  //     "Veg Hakka Noodles",
  //     "300.00",
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //   ],
  //   [
  //     "2",
  //     "Singapuri Noodles",
  //     "300.00",
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //   ],
  //   [
  //     "3",
  //     "Chilli Garlic Noodles",
  //     "300.00",
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //     <Box className="td-flex">
  //       <FormControlLabel control={<IOSSwitch sx={{}} />} />
  //       <FormControl className="w-100">
  //         <OutlinedInput value="" placeholder="Price" className="td-input" />
  //       </FormControl>
  //     </Box>,
  //   ],
  // ];
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card add-merchant">
              <Bredcrum />
              <CardContent>
                <Grid container mt={3} spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Card className="customer_cards">
                      <CardContent className="p-0">
                        <Box mb={2} className="sm-selects p-15">
                          <Typography component="h4" className="sub_title">
                            TABLES
                          </Typography>
                        </Box>
                        <Divider></Divider>
                        <Grid container spacing={2} className="p-15">
                          <Grid item md={12} className="accordion-div">
                            <Accordion>
                              <AccordionSummary
                                // expandIcon={
                                //   <svg
                                //     class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                //     focusable="false"
                                //     aria-hidden="true"
                                //     viewBox="0 0 24 24"
                                //     data-testid="ArrowDropUpIcon"
                                //   >
                                //     <path d="m7 16 4-7 4 7z"></path>
                                //   </svg>
                                // }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="accordian-header"
                              >
                                Table No. 1
                                <Typography
                                  component="p"
                                  className="no-0f-tables"
                                >
                                  4
                                </Typography>
                                <Button
                                  className="btn btn-outline-primary"
                                  onClick={handleClick}
                                >
                                  View More
                                </Button>
                                <Box>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box className="d-flex">
                                  <Typography component="p">POS</Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Phone Orders
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Waiter Log
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">App</Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">Website</Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                {/* <Divider></Divider>
                                <Box
                                  className="d-flex"
                                  justifyContent={"center"}
                                  marginTop={1}
                                >
                                  <Button
                                    className="btn btn-outline-primary"
                                    onClick={handleClick}
                                  >
                                    View More
                                  </Button>
                                </Box> */}
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                          <Grid item md={12} className="accordion-div">
                            <Accordion>
                              <AccordionSummary
                                // expandIcon={
                                //   <svg
                                //     class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                //     focusable="false"
                                //     aria-hidden="true"
                                //     viewBox="0 0 24 24"
                                //     data-testid="ArrowDropUpIcon"
                                //   >
                                //     <path d="m7 16 4-7 4 7z"></path>
                                //   </svg>
                                // }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="accordian-header"
                              >
                                Table No. 2
                                <Typography
                                  component="p"
                                  className="no-0f-tables"
                                >
                                  8
                                </Typography>
                                <Button
                                  className="btn btn-outline-primary"
                                  onClick={handleClick}
                                >
                                  View More
                                </Button>
                                <Box>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box className="d-flex">
                                  <Typography component="p">POS</Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Phone Orders
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Waiter Log
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">App</Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">Website</Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                {/* <Divider></Divider>
                                <Box
                                  className="d-flex"
                                  justifyContent={"center"}
                                  marginTop={1}
                                >
                                  <Button
                                    className="btn btn-outline-primary"
                                    onClick={handleClick}
                                  >
                                    View More
                                  </Button>
                                </Box> */}
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                          <Grid item md={12} className="accordion-div">
                            <Accordion>
                              <AccordionSummary
                                // expandIcon={
                                //   <svg
                                //     class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                //     focusable="false"
                                //     aria-hidden="true"
                                //     viewBox="0 0 24 24"
                                //     data-testid="ArrowDropUpIcon"
                                //   >
                                //     <path d="m7 16 4-7 4 7z"></path>
                                //   </svg>
                                // }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="accordian-header"
                              >
                                Table No. 3
                                <Typography
                                  component="p"
                                  className="no-0f-tables"
                                >
                                  6
                                </Typography>
                                <Button
                                  className="btn btn-outline-primary"
                                  onClick={handleClick}
                                >
                                  View More
                                </Button>
                                <Box>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box className="d-flex">
                                  <Typography component="p">POS</Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Phone Orders
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Waiter Log
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">App</Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">Website</Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                {/* <Divider></Divider>
                                <Box
                                  className="d-flex"
                                  justifyContent={"center"}
                                  marginTop={1}
                                >
                                  <Button
                                    className="btn btn-outline-primary"
                                    onClick={handleClick}
                                  >
                                    View More
                                  </Button>
                                </Box> */}
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Card className="customer_cards">
                      <CardContent className="p-0">
                        <Box mb={2} className="sm-selects p-15">
                          <Typography component="h4" className="sub_title">
                            SEATS
                          </Typography>
                          <Box>
                            {/* <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn bg-white"
                              mr={2}
                            >
                              Vendor
                            </Button> */}
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn bg-white"
                              mr={2}
                            >
                              Submit
                            </Button>
                          </Box>
                        </Box>
                        <Divider></Divider>
                        <Grid container spacing={2}>
                          {/* <Grid item md={12}>
                            <Box mb={2} className="sm-selects p-15">
                              <Typography component="h4" className="sub_title">
                                Noodles
                              </Typography>
                            </Box>
                          </Grid> */}
                        </Grid>
                        {showState && (
                          <Grid container spacing={2} padding={2} mt={1}>
                            <Grid item md={12} className="accordion-div">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={
                                    <svg
                                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                      focusable="false"
                                      aria-hidden="true"
                                      viewBox="0 0 24 24"
                                      data-testid="ArrowDropUpIcon"
                                    >
                                      <path d="m7 16 4-7 4 7z"></path>
                                    </svg>
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="table-accordian-header accordian-header"
                                >
                                  <Typography
                                    component="p"
                                    className="accordion-heading-ss"
                                  >
                                    Table No. 1
                                  </Typography>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    pt={1}
                                    pl={1}
                                  >
                                    <Box className="td-flex">
                                      <FormControlLabel
                                        control={<IOSSwitch sx={{}} />}
                                      />
                                      <Typography
                                        component="p"
                                        className="third-party-vendor-name"
                                      >
                                        POS
                                      </Typography>
                                    </Box>
                                    <Box className="td-flex">
                                      <FormControlLabel
                                        control={<IOSSwitch sx={{}} />}
                                      />
                                      <Typography
                                        component="p"
                                        className="third-party-vendor-name"
                                      >
                                        Phone Orders
                                      </Typography>
                                    </Box>
                                    <Box className="td-flex">
                                      <FormControlLabel
                                        control={<IOSSwitch sx={{}} />}
                                      />
                                      <Typography
                                        component="p"
                                        className="third-party-vendor-name"
                                      >
                                        Waiter Log
                                      </Typography>
                                    </Box>
                                    <Box className="td-flex">
                                      <FormControlLabel
                                        control={<IOSSwitch sx={{}} />}
                                      />
                                      <Typography
                                        component="p"
                                        className="third-party-vendor-name"
                                      >
                                        App
                                      </Typography>
                                    </Box>
                                    <Box className="td-flex">
                                      <FormControlLabel
                                        control={<IOSSwitch sx={{}} />}
                                      />
                                      <Typography
                                        component="p"
                                        className="third-party-vendor-name"
                                      >
                                        Website
                                      </Typography>
                                    </Box>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <MemberTable
                                    columns={ItemColumns}
                                    options={itemOptions}
                                    data={orderItemData}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </Grid>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        {/* <LocationModal ref={ref4} /> */}
      </Box>
    </>
  );
}
