import React from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Switch,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";

import Header from "../Header/Header";
import {
  DataGrid,
  GridToolbar,
  gridClasses,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { OutlinedInput } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Bredcrum from "../Bredcrum";
import Calender from "../Calender/Calender";
import MemberTable from "../Customer/MemberTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FormControlLabel } from "@mui/material";
import qrcode from "../images/qr.png";
import AddTableModal from "./AddTableModal";
// import { useDemoData } from "@mui/x-data-grid-generator";
import persons from "../images/persons.png";

const year = ["2023", "2022", "2021", "2020"];

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const transactionstatementcolumns = [
  { name: "Start Date", options: { filterOptions: { fullWidth: true } } },
  "End Date",
  "Total Amount",
  "Payout Date",
  "Payout Mode",
  "Payment CNF ID",
];
const transactionstatementdata = [
  ["12-08-2023", "15-08-2023", "₹2099", "15-08-2023", "UPI", "#239889498"],
];
const timeoffcolumns = [
  { name: "Date of Leave", options: { filterOptions: { fullWidth: true } } },
  "No of Days",
  "Reason",
  "Date Applied",
  "Status",
  "",
];
const timeoffdata = [
  [
    "01-10-2022",
    "3",
    "Sick",
    "01-10-2022",
    "Approved",
    <Button className="btn btn-outline-primary">View</Button>,
  ],
  [
    "01-10-2022",
    "1",
    "PTO",
    "01-10-2022",
    "Approved",
    <Button className="btn btn-outline-primary">View</Button>,
  ],
];
const pastmanagementColumns = [
  { name: "Employee Name", options: { filterOptions: { fullWidth: true } } },
  "Location",
  "Designation",
  "Date of Joining",
  "Last Working Day",
  "Reason",
  "Status",
];
const pastmanagementdata = [
  [
    "Andrew",
    "Debary",
    "Cashier",
    "05 July 2023",
    "14 July 2023",
    "Sick Leave",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
  ],
  [
    "Zeus",
    "Debary",
    "Cashier",
    "05 July 2023",
    "14 July 2023",
    "Sick Leave",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
  ],
];
const managementColumns = [
  { name: "QR ID", options: { filterOptions: { fullWidth: true } } },
  "Table ID",
  "Table Name",
  "Seats",
  "Description",
];
const managementdata = [
  [<Box component="img" src={qrcode} />, "001", "Table 1", "4", "Lake View"],
  [<Box component="img" src={qrcode} />, "002", "Table 2", "6", "Beach View"],
];

const managementOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const tstatementsColumns = [
  { name: "Start Date", options: { filterOptions: { fullWidth: true } } },
  "End Date",
  "Total Transport",
  "Total Amount",
  "PayOut Date",
  "PayOut Mode",
  "Payment CNF ID",
  "Action",
  "",
  "",
];
const tstatementsdata = [
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
];

const tstatementsOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const columns: GridColDef[] = [
  //   { field: "id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    editable: true,
  },
  {
    field: "location",
    headerName: "Location",
    width: 200,
    editable: true,
  },
  {
    field: "totalTransport",
    headerName: "Total Transport",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "earnings",
    headerName: "Earnings",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "leaves",
    headerName: "Leaves",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "compliance",
    headerName: "Compliance",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "status",
    headerName: "Status",
    type: "number",
    width: 150,
    editable: true,
  },
];

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const rows = [
  { id: 1, driverCity: "Snow", driverName: "Jon", phoneNumber: 8121542167 },
  {
    id: 2,
    driverCity: "Lannister",
    driverName: "Cersei",
    phoneNumber: 8121542167,
  },
  {
    id: 3,
    driverCity: "Lannister",
    driverName: "Jaime",
    phoneNumber: 8121542167,
  },
  { id: 4, driverCity: "Stark", driverName: "Arya", phoneNumber: 8121542167 },
  {
    id: 5,
    driverCity: "Targaryen",
    driverName: "Daenerys",
    phoneNumber: 8121542167,
  },
  {
    id: 6,
    driverCity: "Melisandre",
    driverName: null,
    phoneNumber: 8121542167,
  },
  {
    id: 7,
    driverCity: "Clifford",
    driverName: "Ferrara",
    phoneNumber: 8121542167,
  },
  {
    id: 8,
    driverCity: "Frances",
    driverName: "Rossini",
    phoneNumber: 8121542167,
  },
  { id: 9, driverCity: "Roxie", driverName: "Harvey", phoneNumber: 8121542167 },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const trows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TableBooking() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const ref2 = React.useRef();
  const ref1 = React.useRef();
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card add-merchant">
              <Bredcrum />

              <CardContent>
                <Grid container className="full_content">
                  <Grid xs="12" mt={1}>
                    <Box sx={{ display: "flex" }} className="filters">
                      <Box>
                        <Typography component="p">Table Booking</Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} mt={2}>
                      <Card>
                        <CardContent>
                          <Grid container spacing={2} mb={2}>
                            <Grid item md={4}>
                              <FormControl className="w-100">
                                <Typography component="label">
                                  Customer Name
                                </Typography>
                                <OutlinedInput placeholder="Customer Name" />
                              </FormControl>
                            </Grid>
                            <Grid item md={4}>
                              <FormControl className="w-100">
                                <Typography component="label">
                                  Phone Number
                                </Typography>
                                <OutlinedInput placeholder="Phone Number" />
                              </FormControl>
                            </Grid>
                            <Grid item md={4}>
                              <FormControl className="w-100">
                                <Typography component="label">Email</Typography>
                                <OutlinedInput placeholder="Email" />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container spacing={2} mt={1}>
                            <Grid item md={6} className="border-reservation">
                              <Typography component="p" mb={1}>
                                Make Reservation
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item md={12}>
                                  <FormControl className="w-100">
                                    <Typography component="label">
                                      Party Size
                                    </Typography>
                                    <OutlinedInput placeholder="Enter Party Size" />
                                  </FormControl>
                                </Grid>
                                <Grid item md={6}>
                                  <FormControl className="w-100">
                                    <Typography component="label">
                                      Date
                                    </Typography>
                                    <OutlinedInput type="date" />
                                  </FormControl>
                                </Grid>
                                <Grid item md={6}>
                                  <FormControl className="w-100">
                                    <Typography component="label">
                                      Time
                                    </Typography>
                                    <OutlinedInput type="time" />
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} mt={1}>
                                <Grid item md={12} textAlign={"center"}>
                                  <Button className="btn btn-outline-primary">
                                    Find a Table
                                  </Button>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} mt={1}>
                                <Grid item md={12}>
                                  <Typography>Select a Time</Typography>
                                </Grid>

                                <Grid item md={4}>
                                  <Button className="btn btn-outline-primary">
                                    06.30 PM
                                  </Button>
                                </Grid>
                                <Grid item md={4}>
                                  <Button className="btn btn-outline-primary">
                                    06.45 PM
                                  </Button>
                                </Grid>
                                <Grid item md={4}>
                                  <Button className="btn btn-outline-primary">
                                    07.00 PM
                                  </Button>
                                </Grid>
                                <Grid item md={4}>
                                  <Button className="btn btn-outline-primary">
                                    07.15 PM
                                  </Button>
                                </Grid>
                                <Grid item md={4}>
                                  <Button className="btn btn-outline-primary">
                                    07.30 PM
                                  </Button>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} mt={1}>
                                <Grid item md={12} textAlign={"center"}>
                                  <Button className="btn btn-primary">
                                    Reserve Table
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item md={6}>
                              <Box className="table-booking-padding">
                                <Box className="table-success" mb={1}>
                                  <Typography className="table-name-success">
                                    Table 1
                                  </Typography>
                                  <Box display={"flex"} alignItems={"center"}>
                                    <Box component="img" src={persons} />
                                    <Typography
                                      component="p"
                                      className="table-count-booking"
                                    >
                                      04
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box className="table-danger" mb={1}>
                                  <Typography className="table-name-danger">
                                    Table 2
                                  </Typography>
                                  <Box display={"flex"} alignItems={"center"}>
                                    <Box component="img" src={persons} />
                                    <Typography
                                      component="p"
                                      className="table-count-booking"
                                    >
                                      03
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box className="table-success" mb={1}>
                                  <Typography className="table-name-success">
                                    Table 3
                                  </Typography>
                                  <Box display={"flex"} alignItems={"center"}>
                                    <Box component="img" src={persons} />
                                    <Typography
                                      component="p"
                                      className="table-count-booking"
                                    >
                                      02
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box className="table-success" mb={1}>
                                  <Typography className="table-name-success">
                                    Table 4
                                  </Typography>
                                  <Box display={"flex"} alignItems={"center"}>
                                    <Box component="img" src={persons} />
                                    <Typography
                                      component="p"
                                      className="table-count-booking"
                                    >
                                      08
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <AddTableModal ref={ref2} />
      </Box>
    </>
  );
}
