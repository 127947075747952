import logo from "./bazar-logo.svg";
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";

// function SelectLabels() {
//   const [age, setAge] = React.useState("true");

//   const handleChange = (event: SelectChangeEvent) => {
//     setAge(event.target.value);
//   };
// }

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const card = (
  <React.Fragment>
    <CardContent className="card-content text-center register">
      <Typography className="clr-primary fw-bold" variant="h5" gutterBottom>
        Register
      </Typography>
      <div className="">
        <FormControl className="w-100" variant="standard">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl className="w-100" size="small">
                <Typography component="label" className="float-left">
                  Name of Bussines
                </Typography>

                <BootstrapInput
                  fullWidth
                  id="username-input"
                  placeholder="Bussiness Name"
                />
              </FormControl>
            </Grid>
          </Grid>
        </FormControl>
        <Grid container spacing={2} className="mt-5">
          <Grid item md={6} className="text-start">
            <FormControl className="w-100" size="small">
              <Typography component="label" className="float-left">
                Service Type
              </Typography>
              <Select
                labelId="demo-isolation-label"
                id="isolation-select"
                className="select select-equipment"
                // onChange={handleSelectOxygen}
                // input={<OutlinedInput />}
                defaultValue="Percentage"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Electrical</MenuItem>
                <MenuItem value={20}>Food</MenuItem>
                <MenuItem value={30}>Groceries</MenuItem>
                <MenuItem value={40}>Restaurants</MenuItem>
                <MenuItem value={50}>Theatres</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} className="text-start" mb={1}>
            <FormControl className="w-100" size="small">
              <Typography component="label" className="float-left">
                City
              </Typography>
              <Select
                labelId="demo-isolation-label"
                id="isolation-select"
                className="select select-equipment"
                // onChange={handleSelectOxygen}
                // input={<OutlinedInput />}
                defaultValue="Percentage"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>All Cities</MenuItem>
                <MenuItem value={20}>Visakhapatnam</MenuItem>
                <MenuItem value={30}>Warangal</MenuItem>
                <MenuItem value={40}>Mysore</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mt-1">
          <Grid item xs={6} className="text-start">
            <FormControl className="w-100" size="small">
              <Typography component="label" className="float-left">
                Primary Email
              </Typography>

              <BootstrapInput
                fullWidth
                id="username-input"
                placeholder="Email"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} className="text-start">
            <FormControl className="w-100" size="small">
              <Typography component="label" className="float-left">
                Phone Number
              </Typography>

              <BootstrapInput
                fullWidth
                id="username-input"
                placeholder="Phone Number"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mt-5">
          <Grid item xs={6} className="text-start">
            <FormControl className="w-100" size="small">
              <Typography component="label" className="float-left">
                Password
              </Typography>

              <BootstrapInput
                fullWidth
                id="username-input"
                placeholder="*********"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} className="text-start">
            <FormControl className="w-100" size="small">
              <Typography component="label" className="float-left">
                Confirm Password
              </Typography>

              <BootstrapInput
                fullWidth
                id="username-input"
                placeholder="**********"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          href="/VerificationSuccess"
          className="mt-3 w-50 btn-primary py-2 mx-0"
        >
          Register
        </Button>
        {/* <Button
            variant="outlined"
            className="hvr-grow-shadow login-btn btn-primery _btn-outline py-2 mx-0 mt-3"
          >
            Register
          </Button> */}
        <div className="mt-3">
          <Typography variant="body2" gutterBottom>
            Already have an account ?{" "}
            <button className="btn btn-outline-primary retail-btn">
              Login
            </button>
          </Typography>
        </div>
        <div className="mt-3">
          <Typography className="" variant="body2" gutterBottom>
            Still facing issue ? <Box component="br" />
            Contact{" "}
            <a href="tel:1234567890" className="frgt-pswrd">
              {" "}
              +91-1234567890
            </a>{" "}
            or Email{" "}
            <a href="mailto:someone@example.com" className="frgt-pswrd">
              {" "}
              someone@example.com
            </a>
          </Typography>
        </div>
      </div>
    </CardContent>
  </React.Fragment>
);

export default function Register() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <div className="Absolute-Box text-center">
          <Box sx={{ minWidth: 275, paddingTop: 3 }}>
            <Card variant="outlined">
              <Box
                component="img"
                src={logo}
                className="App-logo"
                alt="logo"
                mt={3}
              />
              {card}
            </Card>
          </Box>
        </div>
      </Container>
    </React.Fragment>
  );
}

// export default App;
