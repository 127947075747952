import React, { useImperativeHandle, forwardRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import {
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  Divider,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const MySwal = withReactContent(Swal);

const StorePickupModal = forwardRef((props, ref3) => {
  const [open, setOpen] = React.useState(false);
  const [passwd, setPasswd] = React.useState(false);
  const handlePassword = () => {
    setPasswd((passwd) => !passwd);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "All Fields Are Mandatory",
    });
  };

  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
    },
  }));

  //   if(openM){
  //     handleOpen();
  //   }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="parent-modal"
      >
        <Box sx={{ ...style }} className="profile-modal">
          <Grid container>
            <Grid item xs={12} className="profile-header p-15">
              <Typography id="parent-modal-title" component="h4">
                RESTAURANT TABLES
              </Typography>
              <CloseIcon onClick={handleClose} />
            </Grid>
          </Grid>
          <Divider></Divider>
          <Box className="modal-height">
            <Grid container className="details-div p-15" spacing={2}>
              {/* <Grid item xs={12} md={6}>
              <FormControl className="w-100">
                <Typography component="label">Table Number</Typography>
                <OutlinedInput placeholder="Table Number" />
              </FormControl>
            </Grid> */}
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary active">
                  Table 1
                </Button>
              </Grid>
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 2</Button>
              </Grid>
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 3</Button>
              </Grid>
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 4</Button>
              </Grid>
            </Grid>

            <Grid container spacing={2} className="details-div p-15">
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 5</Button>
              </Grid>
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 6</Button>
              </Grid>
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 7</Button>
              </Grid>
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 8</Button>
              </Grid>
            </Grid>

            <Grid container spacing={2} className="details-div p-15">
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 9</Button>
              </Grid>
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 10</Button>
              </Grid>
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 11</Button>
              </Grid>
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 12</Button>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="details-div p-15">
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 13</Button>
              </Grid>
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 14</Button>
              </Grid>
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 15</Button>
              </Grid>
              <Grid item md={3} textAlign={"center"}>
                <Button className="btn btn-outline-primary">Table 16</Button>
              </Grid>
            </Grid>
          </Box>

          <Divider></Divider>
          <Box className="p-15 text-right">
            <Button
              variant="outlined"
              color="error"
              className="retail-btn mr-15"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
});

export default StorePickupModal;
