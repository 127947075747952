import React, { useState, forwardRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import id from "../images/ID.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import rupee from "../images/rupee.svg";
import mail from "../images/mail.svg";
import MemberTable from "../Customer/MemberTable";
import test from "../images/default.jpg";
import custicon from "../images/cust-icon.svg";
import upload from "../images/upload.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const shiftdetailsColumns = [
  { name: "S.No", options: { filterOptions: { fullWidth: true } } },
  "Working Days",
  "Timings",
];

const equipmentdetailsColumns = [
  { name: "S.No", options: { filterOptions: { fullWidth: true } } },
  "Name",
  "Number",
];

const uploadDocumentsColumns = [
  { name: "S.No", options: { filterOptions: { fullWidth: true } } },
  "Attachment Name",
  "Expiry Date",
];

const shiftdetailsdata = [];
const managementColumns = [
  { name: "S.No", options: { filterOptions: { fullWidth: true } } },
  "Name",
  "Number",
  "Attachment",
];
const managementdata = [];

const managementOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const AddEmployeeModal = forwardRef((props, ref3) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);

  const viColumns = [
    { name: "Date", options: { filterOptions: { fullWidth: true } } },
    "Inspection",
    "Status",
    "Comments",
  ];

  const viOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content">
          <Box className="modal-header p-15">
            <Typography
              id="modal-modal-title"
              variant="h5"
              className="m-heading"
              component="h5"
            >
              Employee Profile
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          <Grid container spacing={2} mt={1}>
            <Grid item md={12}>
              <Box className="bg-secondary">
                <Typography className="p-info">Personal Information</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} paddingRight={2}>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Name*</Typography>
                <OutlinedInput placeholder="Name" value="Ravi" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Phone Number*</Typography>
                <OutlinedInput placeholder="Phone Number" value="8121542167" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Address*</Typography>
                <OutlinedInput placeholder="Address" value="Gachibowli" />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} paddingRight={2}>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Email ID*</Typography>
                <OutlinedInput
                  placeholder="Email"
                  value="manikanta@intelmor.com"
                />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Gender*</Typography>
                <OutlinedInput placeholder="Gender" value="Male" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Dob*</Typography>
                <OutlinedInput
                  type="Date"
                  placeholder="Dob"
                  value="15 July 2000"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item md={12}>
              <Box className="bg-secondary">
                <Typography className="p-info">Role Details</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} paddingRight={2}>
            <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Job Name*</Typography>
                <OutlinedInput placeholder="Job Name" />
                {/* <Select
                        labelId="demo-isolation-label"
                        id="isolation-select"
                        className="select viewmore-dpdown"
                        defaultValue="Suppliers"
                      >
                        <MenuItem value="Suppliers">Select</MenuItem>
                        <MenuItem value="car">Driver</MenuItem>
                        <MenuItem value="van">Cashier</MenuItem>
                        <MenuItem value="van">Accountant</MenuItem>
                      </Select> */}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Job Type*</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select viewmore-dpdown"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="Suppliers"
                >
                  <MenuItem value="Suppliers">Select</MenuItem>
                  <MenuItem value="part">Part Time</MenuItem>
                  <MenuItem value="full">Full Time</MenuItem>
                  <MenuItem value="freelance">Freelancer</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Location*</Typography>
                <OutlinedInput placeholder="Location" value="Hyderabad" />
                {/* <Select
                        labelId="demo-isolation-label"
                        id="isolation-select"
                        className="select viewmore-dpdown"
                        defaultValue="Suppliers"
                      >
                        <MenuItem value="Suppliers">Select</MenuItem>
                        <MenuItem value="car">Driver</MenuItem>
                        <MenuItem value="van">Cashier</MenuItem>
                        <MenuItem value="van">Accountant</MenuItem>
                      </Select> */}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} paddingRight={2}>
            <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Pay Type*</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select viewmore-dpdown"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="Suppliers"
                >
                  <MenuItem value="Suppliers">Select</MenuItem>
                  <MenuItem value="day">Per Day</MenuItem>
                  <MenuItem value="hour">Per Hour</MenuItem>
                  <MenuItem value="week">Per Week</MenuItem>
                  <MenuItem value="month">Per Month</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Pay Period Unit*</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select viewmore-dpdown"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="Suppliers"
                >
                  <MenuItem value="Suppliers">Select</MenuItem>
                  <MenuItem value="day">Per Hour / 60 Mins</MenuItem>
                  <MenuItem value="day">Per Day / 8 Hours</MenuItem>
                  <MenuItem value="week">Per Week / 6 Days</MenuItem>
                  <MenuItem value="month">Per Month / 26 Days</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Base Pay*</Typography>
                <OutlinedInput placeholder="Base Pay" />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item md={12}>
              <Box className="bg-secondary">
                <Typography className="p-info">Complaince</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} mb={3} paddingRight={2}>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Name</Typography>
                <OutlinedInput placeholder="Name" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Number</Typography>
                <OutlinedInput placeholder="Number" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Expiry Date</Typography>
                <OutlinedInput placeholder="Expiray Date" type="date" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">File Upload</Typography>
                <OutlinedInput placeholder="Expiray Date" type="file" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <Button
                variant="outlined"
                color="error"
                mb={2}
                className="btn-outline-primary"
              >
                Upload
              </Button>
            </Grid>
            <Grid item md={12}>
              <MemberTable
                columns={managementColumns}
                options={managementOptions}
                data={managementdata}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item md={12}>
              <Box className="bg-secondary">
                <Typography className="p-info">
                  Financial Information
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} mb={3} paddingRight={2}>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Bank Name*</Typography>
                <OutlinedInput placeholder="Bank Name" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Branch Name*</Typography>
                <OutlinedInput placeholder="Branch Name" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">IFSC Code*</Typography>
                <OutlinedInput placeholder="IFSC Code" />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} mb={3}>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Account Number*</Typography>
                <OutlinedInput
                  placeholder="IFSC Code"
                  value="164901000004325"
                />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Account Holder Name*</Typography>
                <OutlinedInput placeholder="IFSC Code" value="Suresh" />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item md={12}>
              <Box className="bg-secondary">
                <Typography className="p-info">Username & Password</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} mb={3}>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Username*</Typography>
                <OutlinedInput placeholder="" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Password*</Typography>
                <OutlinedInput placeholder="" />
              </FormControl>
            </Grid>
            <Grid item md={4} textAlign={"center"}>
              <Button
                variant="outlined"
                color="error"
                mb={2}
                className="btn-outline-primary"
              >
                Update
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item md={12}>
              <Box className="bg-secondary">
                <Typography className="p-info">Detailed Information</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="custom-tab"
              >
                {/* <Tab label="Shift Details" {...a11yProps(0)} /> */}
                <Tab label="Equipment Details" {...a11yProps(1)} />
                <Tab label="Upload Documents" {...a11yProps(0)} />
              </Tabs>
            </Grid>
            <Grid item md={12}>
              {/* <TabPanel value={value} index={0}>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Days</Typography>
                      <OutlinedInput placeholder="" type="date" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Start Time</Typography>
                      <OutlinedInput placeholder="" type="time" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">End TIme</Typography>
                      <OutlinedInput placeholder="" type="time" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <Button
                      variant="outlined"
                      color="error"
                      mb={2}
                      className="btn-outline-primary"
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item md={12}>
                    <MemberTable
                      columns={shiftdetailsColumns}
                      options={managementOptions}
                      data={shiftdetailsdata}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
               */}
              <TabPanel value={value} index={0}>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Name*</Typography>
                      <OutlinedInput placeholder="" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Number*</Typography>
                      <OutlinedInput placeholder="" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Choose File</Typography>
                      <OutlinedInput placeholder="" type="file" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <Button
                      variant="outlined"
                      color="error"
                      mb={2}
                      className="btn-outline-primary"
                    >
                      Upload
                    </Button>
                  </Grid>
                  <Grid item md={12}>
                    <MemberTable
                      columns={equipmentdetailsColumns}
                      options={managementOptions}
                      data={shiftdetailsdata}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">
                        Attachments Name*
                      </Typography>
                      <OutlinedInput placeholder="" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Expiry Date*</Typography>
                      <OutlinedInput placeholder="" type="date" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Choose File</Typography>
                      <OutlinedInput placeholder="" type="file" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <Button
                      variant="outlined"
                      color="error"
                      mb={2}
                      className="btn-outline-primary"
                    >
                      Upload
                    </Button>
                  </Grid>
                  <Grid item md={12}>
                    <MemberTable
                      columns={uploadDocumentsColumns}
                      options={managementOptions}
                      data={shiftdetailsdata}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Box textAlign={"right"} marginX={2} marginY={2}>
            <Button
              variant="outlined"
              color="error"
              mb={2}
              className="btn-outline-primary"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={addOpen}
        onClose={handleCloseAdd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="add-user-modal" sx={style}>
          <Box className="add-user-div p-15">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add New user
            </Typography>
            <CloseIcon onClick={handleCloseAdd} />
          </Box>
          <Divider></Divider>
          <Grid
            className="inputfield-container"
            container
            spacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
            mt={2}
          >
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">First Name</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Last Name</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Phone No</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Email</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Password</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Button
            variant="outlined"
            color="error"
            mt={2}
            mb={2}
            className="save-btn p-15"
          >
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  );
});

export default AddEmployeeModal;
