import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput, Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import productview from "../images/product-view.jpg";
import MemberTable from "../Customer/MemberTable";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import AddProductModal from "./AddProductModal";
import status from "../images/order.svg";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ViewSuppliesModal = forwardRef((props, ref3) => {
  const handleClick = () => {
    setShowState(!showState);
  };
  const [showState, setShowState] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [edit, setEdit] = React.useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const changeEdit = () => setEdit((edit) => !edit);
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleOpenEdit = () => setShow(true);
  const handleCloseAdd = () => setAddOpen(false);

  const data = [
    [
      "12345",
      "Sreenivasa Groceries",
      "67388389",
      "12 Aug 2023",
      "13 Aug 2023",
      "25",
      "12 Aug 2024",
    ],
  ];

  const memeberColumns = [
    "Batch Id",
    { name: "Supplier Name", options: { filterOptions: { fullWidth: true } } },
    "PO Number",
    "PO Date",
    "Delivery Date",
    "Qty",
    // "Sale Quantity",
    "Expiry Date",
  ];

  const managementColumns = [
    { name: "Item Name", options: { filterOptions: { fullWidth: true } } },
    "UOM",
    "No. of Units",
  ];
  const managementdata = [["India Gate Basmati Rice", "gm", "150gms"]];

  const options = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
  };

  const managementOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const ref4 = useRef();

  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="view-more-content retail-content sni-content"
        >
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              INGREDIENT ID - 538838
            </Typography>
            <Box className="sub-modal-header">
              {/* <Button
                variant="outlined"
                color="error"
                mt={2}
                mb={2}
                onClick={changeEdit}
                className="retail-btn  mr-2"
              >
                {edit ? "Edit" : "Save"}
              </Button> */}
              <CloseIcon onClick={handleClose} />
            </Box>
          </Box>
          <Divider></Divider>
          <Box className="modal-body">
            <Grid className="add_new_item">
              <Card>
                <CardContent>
                  {/* <Typography component="h4" className="sni-title">
                        SKU ID - 454989
                      </Typography> */}

                  <Box className="images d-flex">
                    <Box className="position-relative">
                      <Box className="upload__img-close"></Box>
                      <Box component="img" src={productview} />
                    </Box>

                    {/* <Box className="position-relative">
                          <Box className="upload__img-close"></Box>
                          <Box component="img" src={dal3} />
                        </Box>
    
                        <Box className="position-relative">
                          <Box className="upload__img-close"></Box>
                          <Box component="img" src={dal4} />
                        </Box> */}
                  </Box>
                  <Box
                    mt={2}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      className="btn-upload"
                    >
                      Upload image
                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        disabled={edit ? true : false}
                      />
                    </Button>
                    {/* <Box className="information-div quality-count-block">
                      <Box component="img" src={status} />
                      <Box>
                        <Typography component="label">
                          Available Stock
                        </Typography>
                        <Typography component="p" className="quantity-count">
                          100 Kgs
                        </Typography>
                      </Box>
                    </Box> */}
                  </Box>
                  <Grid container spacing={3} mt={1} mb={2}>
                    <Grid item xs={12} md={3}>
                      <FormControl className="w-100">
                        <Typography component="label">
                          Ingredient Name
                        </Typography>
                        <OutlinedInput placeholder="Ingredient Name" value="" />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl className="w-100">
                        <Typography component="label">Brand</Typography>
                        <OutlinedInput placeholder="Brand Name" value="" />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl className="w-100">
                        <Typography component="label">Segment</Typography>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="select"
                        >
                          <MenuItem value="select">Please Select</MenuItem>
                          <MenuItem value="weight">Noodles</MenuItem>
                          <MenuItem value="volume">Breads</MenuItem>
                          <MenuItem value="count">Masala</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl className="w-100">
                        <Typography component="label">Sub Segment</Typography>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="select"
                        >
                          <MenuItem value="select">Please Select</MenuItem>
                          <MenuItem value="weight">Sandwich Bread</MenuItem>
                          <MenuItem value="volume">Burger Bread</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} md={12}>
                        <Box className="float-right">
                          <Button className="btn btn-outline-primary">
                            Next
                          </Button>
                        </Box>
                      </Grid> */}
                  </Grid>
                  <Divider></Divider>

                  <Typography component="h4" className="font-bold" marginY={2}>
                    UOM MEASUREMENT
                  </Typography>
                  <Grid container spacing={2} mb={2}>
                    <Grid item md={4}>
                      <FormControl className="w-100">
                        <Typography component="label">Metric</Typography>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="select"
                        >
                          <MenuItem value="select">Please Select</MenuItem>
                          <MenuItem value="weight">Weight</MenuItem>
                          <MenuItem value="volume">Volume</MenuItem>
                          <MenuItem value="count">Count</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl className="w-100">
                        <Typography component="label">UOM</Typography>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="select"
                        >
                          <MenuItem value="select">Please Select</MenuItem>
                          <MenuItem value="weight">Kg</MenuItem>
                          <MenuItem value="volume">Litre</MenuItem>
                          <MenuItem value="count">Ounce</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl className="w-100">
                        <Typography component="label">No of Units</Typography>
                        <OutlinedInput />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item md={12}>
                      <Box className="float-right">
                        <Button className="btn btn-outline-primary">
                          Next
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider></Divider>
                  <Grid container mt={1}>
                    <Typography component="h4" className="font-bold">
                      NOTIFICATION
                    </Typography>
                    <Grid container spacing={2} mt={1}>
                      <Grid item md={4}>
                        <FormControlLabel
                          className="custom-checkbox"
                          control={<Checkbox />}
                          label="IdleStock"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <FormControl className="w-100 custom-fc">
                          <Typography component="label" mr={2}>
                            No of Days
                          </Typography>
                          <OutlinedInput />
                        </FormControl>
                      </Grid>
                      <Grid item md={4} display={"flex"} alignItems={"center"}>
                        <Typography component="p" className="small">
                          After ___ Days an Alert will be Generated Saying the
                          Item is Idle
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={1} mb={1}>
                      <Grid item md={4}>
                        <FormControlLabel
                          className="custom-checkbox"
                          control={<Checkbox />}
                          label="LowStock"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <FormControl className="w-100 custom-fc ">
                          <Typography component="label" mr={2}>
                            Threshold Value
                          </Typography>
                          <OutlinedInput />
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <Typography component="p" className="small">
                          After Stock Comes Below ___ then an Alert will be
                          Generated Saying low stock into reports
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={2} mt={1} mb={1}>
                        <Grid item md={12}>
                          <Box className="float-right">
                            <Button className="btn btn-outline-primary">
                              Next
                            </Button>
                          </Box>
                        </Grid>
                      </Grid> */}
                  </Grid>
                  <Divider></Divider>
                  <Grid container>
                    <Box className="flex-between w-100" mt={2}>
                      <Typography className="font-bold" component="h4">
                        PRODUCT HISTORY
                      </Typography>
                      {/* <Typography component="p" className="ag-skuid">
                              BEV-TE-FSD-FF-23LI
                            </Typography> */}
                      <Button
                        variant="outlined"
                        mt={2}
                        mb={2}
                        className="retail-btn"
                        mr={2}
                        onClick={() => ref4.current.log()}
                      >
                        Add Product
                      </Button>
                    </Box>
                    <Box className="member-table w-100">
                      <MemberTable
                        columns={memeberColumns}
                        options={options}
                        data={data}
                      />
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Box>
          <Divider></Divider>
          <Box className="p-15">
            <Button
              variant="outlined"
              color="error"
              mt={2}
              mb={3}
              className="retail-btn float-right"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
          <AddProductModal ref={ref4} />
        </Box>
      </Modal>
    </div>
  );
});

export default ViewSuppliesModal;
