import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AddProductModal = forwardRef((props, ref2, ref4) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useImperativeHandle(ref2, () => ({
    log() {
      handleOpen();
    },
  }));

  useImperativeHandle(ref4, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content add-product ">
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              PRODUCT DETAILS
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>

          <Divider></Divider>
          <Box className="ml-20" mt={2}>
            <Typography component="h4" className="sub_title">
              SUPPLIER & QUANTITY
            </Typography>
          </Box>
          <Grid container spacing={2} className="p-15" mt={1}>
            <Grid item md={4}>
              <Box className="information-div">
                <FormControl className="w-100">
                  <Typography component="label">Supplier Name</Typography>
                  <OutlinedInput placeholder="Supplier Name" />
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="information-div">
                <FormControl className="w-100">
                  <Typography component="label">PO Number</Typography>
                  <OutlinedInput placeholder="PO Number" />
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="information-div">
                <FormControl className="w-100">
                  <Typography component="label">PO Date</Typography>
                  <OutlinedInput value="" type="date" />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="p-15" mt={1}>
            <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Quantity</Typography>
                <OutlinedInput placeholder="Quantity" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Cost Price</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Date</Typography>
                <OutlinedInput type="date" />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="p-15">
            <Grid item md={12}>
              <FormControl className="w-100">
                <Typography component="label">Description</Typography>
                <TextareaAutosize value="" minRows={6} className="br-6" />
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          {/* <Box className="ml-20" mt={2}>
            <Typography component="h4" className="sub_title">
              PRICE
            </Typography>
          </Box>
          <Grid container spacing={3} className="p-15">
            <Grid item xs={2} sm={3} md={4}>
              <Box className="information-div">
                <FormControl className="w-100">
                  <Typography component="label">MSR Price</Typography>
                  <OutlinedInput value="" placeholder="Item Name" />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={2} sm={3} md={4}>
              <Box className="information-div">
                <FormControl className="w-100">
                  <Typography component="label">Cost Price</Typography>
                  <OutlinedInput value="" placeholder="Item Name" />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={2} sm={3} md={4}>
              <Box className="information-div">
                <FormControl className="w-100">
                  <Typography component="label">Sale Price</Typography>
                  <OutlinedInput value="" placeholder="Item Name" />
                </FormControl>
              </Box>
            </Grid>
          </Grid> */}
          <Box className="ml-20" mt={2}>
            <Typography component="h4" className="sub_title">
              NOTIFICATION
            </Typography>
          </Box>

          <Grid container spacing={3} className="p-15">
            <Grid item xs={2} sm={12} md={3}>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Perishable" />
              </FormGroup>
            </Grid>
            <Grid item xs={2} sm={12} md={4}>
              <Box className="information-div">
                <FormControl className="flex flex-row">
                  <Typography component="label">Expiry Date</Typography>
                  <OutlinedInput value="" type="date" />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={2} sm={12} md={5}>
              <Box className="information-div">
                <FormControl className="flex flex-row">
                  <Typography component="label">Expiry Reminder</Typography>
                  <OutlinedInput value="" />
                  <Typography component="label">Days</Typography>
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Divider></Divider>
          <Box className="p-15">
            <Button
              variant="outlined"
              color="error"
              mt={2}
              mb={3}
              className="retail-btn float-right"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
});

export default AddProductModal;
