import React, { useImperativeHandle, forwardRef } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import Calender from "../Calender/Calender";
import { OutlinedInput, FormControl } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SearchBar from "material-ui-search-bar";
import ExpandableRowTable from "../Customers/ExpandableRowTable";
import Bredcrum from "../Bredcrum";

const drawerWidth = 240;

export default function SalesReport() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const memeberColumns = [
    { name: "Sales Channel", options: { filterOptions: { fullWidth: true } } },
    "Total Transactions",
    "Gross Amount",
    "Discount",
    "Refunds",
    "Fees",
    "Taxes",
    "Invoice Value",
    "COGS",
    "Net Total",
  ];

  const salesOptions = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,

    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const data = [
    [
      "Total",
      "15",
      "₹ 1,26,000.00",
      "₹ 35,400.00",
      "₹ 12000.00",
      "₹ 7300.00",
      "₹ 6750.00",
      "₹ 43,300.00",
      "₹ 85,200.00",
      "₹ 1,38,600.00",
    ],
    [
      "Cash",
      "4",
      "₹ 10000.00",
      "₹ 1000.00",
      "₹ 1000.00",
      "₹ 500.00",
      "₹ 500.00",
      "₹ 10000.00",
      "₹ 2000.00",
      "₹ 7000.00",
    ],
  ];
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <CardContent>
                <Grid container className="full_content">
                  <Bredcrum />
                  <Divider className="bredcum-hr"></Divider>
                  <Grid xs="12" mt={3}>
                    <Box
                      sx={{ display: "flex" }}
                      className="filters sales-filters"
                    >
                      <Box className="flex">
                        <Calender className="calenders" />
                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          Today
                        </Button>

                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          This Month
                        </Button>
                      </Box>
                      <Box className="flex">
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">Select</MenuItem>
                          <MenuItem value="visakhapatnam">PDF</MenuItem>
                          <MenuItem value="vijayawada">Excel</MenuItem>

                          <MenuItem value="kurnool">Image</MenuItem>
                        </Select>
                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          Download
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid container mt={3}>
                    <Grid
                      xs="12"
                      mt={2}
                      mb={2}
                      className="transport-div salesreport-container"
                    >
                      <Box className="filters-group">
                        <Box className="sales-report">
                          <Typography component="h4">1,26,000</Typography>
                          <Typography
                            component="p"
                            className="btn-primary-gradient"
                          >
                            Total Gross Amt
                          </Typography>
                        </Box>
                        <Box className="sales-report">
                          <Typography component="h4">35,400</Typography>
                          <Typography
                            component="p"
                            className="btn-secondary-gradient"
                          >
                            Total Discount
                          </Typography>
                        </Box>
                        <Box className="sales-report">
                          <Typography component="h4">12,000</Typography>
                          <Typography
                            component="p"
                            className="btn-purple-gradient"
                          >
                            Total Refunds
                          </Typography>
                        </Box>
                        <Box className="sales-report">
                          <Typography component="h4">7,300</Typography>
                          <Typography
                            component="p"
                            className="btn-success-gradient"
                          >
                            Total Fee
                          </Typography>
                        </Box>
                        <Box className="sales-report">
                          <Typography component="h4">6,750</Typography>
                          <Typography
                            component="p"
                            className="btn-info-gradient"
                          >
                            Total Taxes
                          </Typography>
                        </Box>
                        <Box className="sales-report">
                          <Typography component="h4">43,300</Typography>
                          <Typography
                            component="p"
                            className="btn-warning-gradient"
                          >
                            No Of Orders
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container mt={3}>
                  <Grid item xs={12}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box className="member-table m-100">
                          <ExpandableRowTable
                            columns={memeberColumns}
                            options={salesOptions}
                            data={data}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
      </Box>
    </>
  );
}
