import React, { useRef } from "react";
import customers from "../src/images/customers.svg";
import logout from "../src/images/logout.svg";
import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileModal from "./ProfileModal";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchBar from "material-ui-search-bar";
import FormControl, { useFormControl } from "@mui/material/FormControl";

export default function Bredcrum() {
  const ref = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const logoutDashboard = () => {
    navigate("/");
  };
  return (
    <Grid container className="main-breadcrumb">
      <Grid container className="full_content bazaar-breadcrumb">
        <Grid className="inner-bredcum" xs="12">
          <Breadcrumbs aria-label="breadcrumb">
            {/* <Link underline="hover" color="inherit" href="/">
              Dashboard
            </Link> */}
            {location.pathname.split("/").map((item) => {
              if (item.length > 0) {
                return (
                  <Typography color="text.primary" className="bredcum-text">
                    {item}
                  </Typography>
                );
              }
            })}
            {/* <Link underline="hover" color="inherit" href="#">
                      Account
                    </Link> */}
            {/* <Typography color="text.primary">Add Merchant</Typography> */}
          </Breadcrumbs>
          <Box className="icons bredcum-icons">
            <SearchBar className="search-bar" />
            {/* <FormControl>
              <Select
                labelId="demo-isolation-label"
                id="isolation-select"
                className="select"
                defaultValue="Suppliers"
              >
                <MenuItem value="Suppliers">Select</MenuItem>
                <MenuItem value="car">Supplier</MenuItem>
                <MenuItem value="van">Retailer</MenuItem>
                <MenuItem value="van">Services</MenuItem>
              </Select>
            </FormControl> */}
            <PersonIcon onClick={() => ref.current.log()} />

            <NotificationsIcon />
            <LogoutIcon onClick={logoutDashboard} ml={2} />
          </Box>
        </Grid>
      </Grid>
      <ProfileModal ref={ref} />
    </Grid>
  );
}
