import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import MemberTable from "../Customer/MemberTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const TableManagementEditModal = forwardRef((props, ref2, ref4) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);

  const selectColumns = [
    { name: "Name", options: { filterOptions: { fullWidth: true } } },
    "Contact No",
    "Location",
    {
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Checkbox />;
        },
      },
    },
  ];
  const selectData = [
    ["patnaik", "9513574862", "Kakinada"],
    ["Neeha", "7084327025", "Vizianagram"],
    ["naveen", "8624791305", "Visakhapatnam"],
  ];

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];
  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useImperativeHandle(ref2, () => ({
    log() {
      handleOpen();
    },
  }));

  useImperativeHandle(ref4, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content add-product ">
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              EDIT TABLE DETAILS
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>

          <Grid
            container
            mt={1}
            mb={3}
            className="pickers"
            spacing={2}
            paddingRight={2}
          >
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Table ID</Typography>
                <OutlinedInput placeholder="ID" value="001" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Table Name</Typography>
                <OutlinedInput placeholder="Table Name" value="Table 1" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Seat Capacity</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select viewmore-dpdown"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="Suppliers"
                >
                  <MenuItem value="Suppliers">Select</MenuItem>
                  <MenuItem value="car">2</MenuItem>
                  <MenuItem value="van">4</MenuItem>
                  <MenuItem value="jeep">8</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Customer Name</Typography>
                <OutlinedInput placeholder="Customer Name" value="Sreenivas" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Phone Number</Typography>
                <OutlinedInput placeholder="Phone Number" value="8121542167" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Booking For</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select viewmore-dpdown"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="Suppliers"
                >
                  <MenuItem value="Suppliers">Select</MenuItem>
                  <MenuItem value="car">2</MenuItem>
                  <MenuItem value="van">4</MenuItem>
                  <MenuItem value="jeep">8</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Zone</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select viewmore-dpdown"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="Suppliers"
                >
                  <MenuItem value="Suppliers">Select</MenuItem>
                  <MenuItem value="car">Pool Side</MenuItem>
                  <MenuItem value="van">Terrace</MenuItem>
                  <MenuItem value="jeep">Near Stage</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Waiter Name</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select viewmore-dpdown"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="Suppliers"
                >
                  <MenuItem value="Suppliers">Select</MenuItem>
                  <MenuItem value="car">Raju</MenuItem>
                  <MenuItem value="van">Eswar</MenuItem>
                  <MenuItem value="jeep">Teja</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Waiter Phone Number</Typography>
                <OutlinedInput placeholder="Phone Number" value="9063476257" />
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Booking Date</Typography>
                <OutlinedInput type="date" />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Booking Time</Typography>
                <OutlinedInput type="time" />
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Box className="p-15" mb={2}>
            <Button
              variant="outlined"
              color="error"
              className="retail-btn float-right"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
});

export default TableManagementEditModal;
