import React, { useImperativeHandle, forwardRef } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import reddot from "../images/reddot.png";
import Calender from "../Calender/Calender";
import { Image, InputOutlined, Label, Phone } from "@mui/icons-material";
import AddCustomerModal from "../Customer/AddCustomerModal";
import id from "../images/ID.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import MemberTable from "../Customer/MemberTable";
import EditModal from "../Customer/EditModal";
import ViewMoreModal from "../Customer/ViewMoreModal";
import home from "../images/home.svg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Bredcrum from "../Bredcrum";
import graph from "../images/graph.png";
import lord from "../images/metal-ganesh.png";
import DatePicker from "react-datepicker";

const drawerWidth = 240;

const year = ["2023", "2022", "2021", "2020"];

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function MessagingHub() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = React.useState(true);

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();

  const jobColumns = [
    { name: "ApplicationId", options: { filterOptions: { fullWidth: true } } },
    "Applicant Name",
    "Phone",
    "Email",
  ];

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);
  const [valuep, setValueP] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeP = (event, newValue) => {
    setValueP(newValue);
  };
  const pcColumns = [
    { name: "MESSAGE ID", options: { filterOptions: { fullWidth: true } } },
    "DATE",
    "SENDER NAME",
    "MESSAGES",
    "CHANNEL",
    "COST",
  ];
  const selectColumns = [
    { name: "Name", options: { filterOptions: { fullWidth: true } } },
    "Contact No",
    "Location",
  ];
  const selectData = [
    ["#258974", "09 mar 2021", "naveen", "3", "SMS", "Rs 5"],
    ["#258414", "14 Jan 2021", "patnaik", "8", "Whats App"],
    ["naveen", "8624791305", "Visakhapatnam"],
  ];

  const pcData = [
    ["old", "Grocires", "Percentage", "09 Mar 2021", "29 Mar 2021"],
    ["old", "Grocires", "Percentage", "09 Mar 2021", "29 Mar 2021"],
    ["old", "Grocires", "Percentage", "09 Mar 2021", "29 Mar 2021"],
    ["New1", "Food", "Discount", "11 Feb 2021", "19 Feb 2021"],
  ];

  const pcOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: true,
    responsive: "scrollMaxHeight",
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const jobOptions = {
    search: searchBtn,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: "dropdown",
    selectableRows: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid xs="12" mt={3}>
                    <Box
                      sx={{ display: "flex" }}
                      className="filters sales-filters"
                    >
                      <Box className="flex">
                        <Calender className="calenders" />
                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          Today
                        </Button>

                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          Yesterday
                        </Button>
                      </Box>
                      <Box className="flex">
                        {/* <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Cities</MenuItem>
                          <MenuItem value="visakhapatnam">
                            Visakhapatnam
                          </MenuItem>
                          <MenuItem value="vijayawada">Vijayawada</MenuItem>

                          <MenuItem value="kurnool">kurnool</MenuItem>
                        </Select> */}

                        {/* <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          defaultValue="Orders"
                        >
                          <MenuItem value="Orders">All Orders</MenuItem>
                          <MenuItem value="visakhapatnam">New</MenuItem>
                          <MenuItem value="Completed">Completed</MenuItem>

                          <MenuItem value="Progress">In Progress</MenuItem>
                        </Select> */}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={7}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h6">MESSAGING HUB</Typography>

                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            className="retail-btn"
                          >
                            Download
                          </Button>
                        </Box>
                        <Box mt={2}>
                          <Box className="member-table w-100">
                            <MemberTable
                              columns={pcColumns}
                              options={pcOptions}
                              data={pcData}
                            />
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Card className="customer_cards">
                      <CardContent className="customer_information applicant_information">
                        <Box>
                          <Typography component="h5">
                            Message ID - #235690
                          </Typography>
                        </Box>

                        <Box mt={2} mb={2}>
                          <Box className="flex-between">
                            <Box>
                              <Typography component="p">Sender Name</Typography>
                              <Typography component="label">
                                Srinivas Rao
                              </Typography>
                            </Box>
                            <Box>
                              <Typography component="p">Channel</Typography>
                              <Typography component="label">SMS</Typography>
                            </Box>
                            <Box>
                              <Typography component="p">
                                No of Customers
                              </Typography>
                              <Typography component="label">450</Typography>
                            </Box>
                          </Box>
                          <Box mt={2}>
                            <Box component="p">Message</Box>
                            <Typography component="label" className="w-50">
                              A discount is the difference between the original
                              price and the lower price it is being sold at. An
                              offer is a deal where a product is normally sold
                              at a discount.
                            </Typography>
                          </Box>

                          <Box mt={2} mb={2}>
                            <Box className="flex">
                              <Box>
                                <Typography component="p">Date</Typography>
                                <Typography component="label">
                                  10 Jan 2020
                                </Typography>
                              </Box>
                              <Box ml={4}>
                                <Typography component="p">Time</Typography>
                                <Typography component="label">
                                  03:00 PM
                                </Typography>
                              </Box>
                            </Box>
                          </Box>

                          <Divider></Divider>
                          <Box mt={2} mb={2}>
                            <Box>
                              <Typography component="h5" className="font-bold">
                                CUSTOMERS
                              </Typography>
                            </Box>

                            <Box className="member-table w-100" mt={2}>
                              <MemberTable
                                columns={selectColumns}
                                options={pcOptions}
                                data={selectData}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <AddCustomerModal ref={ref} />
        <EditModal ref={ref1} />
        <ViewMoreModal ref={ref3} />
      </Box>
    </>
  );
}
